import React from 'react';
import { Button } from 'antd';
import { AlertOutlined, AlertFilled } from '@ant-design/icons';


class ArmIcon extends React.PureComponent {

    render() {

        const { isArmed, disabled, onClick } = this.props

        return (
            <Button
                disabled={disabled}
                onClick={onClick}
                shape="circle"
                size="small"
                type={isArmed ? "primary" : "default"}
            >
                {isArmed ? 
                <AlertFilled style={{ marginRight: 0 }} /> :
                <AlertOutlined style={{ marginRight: 0 }} />}
            </Button>
        )

    }
}

export default (ArmIcon);