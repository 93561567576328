import React from 'react';
import Logo from "../../assets/wcctv-large.png";
import NotificationIcon from "./NotificationIcon";
import AudioIcon from "./AudioIcon";
import RelayIcon from "./RelayIcon";
import PlayAudioComponent from './PlayAudioComponent';
import dayjs from 'dayjs';
import Sider from '../setup/Sider';
import ArmIcon from './ArmIcon';
import * as ROUTES from '../../constants/routes';
import { AudioMutedOutlined, MenuOutlined, AudioFilled } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { setAccess, setLoggedOut, select } from "../../api/actions";
import { Icon, Menu, Divider, Button, Drawer, message } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeCookie, unixToFormatted } from '../Utilities';

function mapDispatchToProps(dispatch) {
    return {
        setAccess: access => dispatch(setAccess(access)),
        setLoggedOut: loggedOut => dispatch(setLoggedOut(loggedOut)),
        select: record => dispatch(select(record))
    };
}

function mapStateToProps(state) {
    return { 
        currentTop: state.currentTop,
        currentLeft: state.currentLeft,
        access: state.access,
        processMessage: state.processMessage  
    };
}

class Header extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            isRecordingSmallLayout: false,
            isRecordingMediumLayout: false,
            isRecordingLargeLayout: false,
            openDrawer: false,
            extendDrawer: true,
            relayItems: [],
            notificationItems: [],
            notificationId: 0,
            isArmed: false
        };

        this.nvrLabel = "NVR-Device"
        this.debug = window.config.DEBUG
    }

    componentDidMount() {        
        this.getRelayOutputs()
        this.getRelayOutputsTimer = !this.debug && setInterval(() => this.getRelayOutputs(), 10000)
        
        this.getNotifications()
        this.getNotificationsTimer = !this.debug && setInterval(() => this.getNotifications(), 10000)

        this.getArmedState()
        this.getArmedStateTimer = !this.debug && setInterval(() => this.getArmedState(), 10000)

    }
    
    componentWillUnmount() {
        clearInterval(this.getRelayOutputsTimer)
        clearInterval(this.getNotificationsTimer)
        clearInterval(this.getArmedStateTimer)
    }

    getArmedState() {
        if (this.props.access) {
            this.props.processMessage([{
                request: "armed",
                method: "get",
                params: []
            }])
            .then(res => {
                const armed = !!res["armed"].params[0]
                this.debug && console.log("[Header] getArmedState", armed)
                if (armed !== this.state.isArmed) {
                    this.setState({ isArmed: armed })
                }
            })
            .catch(error => {
                console.error("[Header] getArmedState failed", error);
                this.setState({ error });
            })
        }
    }

    getRelayOutputs() {
        if (this.props.access) {
            this.props.processMessage([{
                request: "relay_outputs",
                method: "get",
                params: []
            }])
            .then(res => {
                const relay_outputs = res["relay_outputs"].params[0].states
                this.debug && console.log("get relay_outputs", relay_outputs)
                this.setState({ relayItems: relay_outputs })
            })
            .catch(error => {
                console.error("[Header] getRelayOutputs", error);
                this.setState({ error });
            })
        }
    }

    setRelayOutput = (key, checked) => {
        if (this.props.access) {
            const value = checked ? 1 : 0
            this.props.processMessage([{
                request: "relay_outputs",
                method: "set",
                params: [{
                    states: [{ 
                        key,
                        value
                    }]
                }]
            }])
            .then(() => {
                this.debug && console.log("[Header] setRelayOutput", key, "to", checked)
                this.setState((prevState) => ({ relayItems: prevState.relayItems.map(elem => elem.key === key ? { ...elem, value } : elem )}))
            })
            .catch(error => {
                console.error("[Header] setRelayOutput", error);
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.");
            })
        }
    }

    getNotifications() {
        if (this.props.access) {
            this.props.processMessage([
                {
                    request: "notifications",
                    method: "get",
                    params: [
                        this.state.notificationId ? {
                            id: this.state.notificationId
                        } : {
                            triggered: dayjs().subtract("10", "minute").unix() // last ten minutes as unix timestamp
                        }
                    ],
                    pagination: {
                        options: [
                            {
                                filter: "gt",
                                sort: "descend"
                            }
                        ]
                    }
                }
            ])
            .then(res => {

                var notificationItems = res["notifications"].params;

                if (notificationItems) {

                    notificationItems = notificationItems
                        .map(notification => {
                                
                            notification["title"] = notification.event ? notification.event : "-";
                            notification["description"] = notification.device ? notification.device : this.nvrLabel;
                            notification["datetime"] = notification.triggered ? unixToFormatted(notification.triggered) : "-";
                            notification["clickClose"] = true;
                            notification["avatar"] = <Icon type="check" /> // info

                            if (notification?.level === 1) { // warning
                                notification["avatar"] = <Icon type="warning" />
                            }   

                            if (notification?.level === 2) { // error
                                notification["avatar"] = <Icon type="stop" />
                            }

                            if (notification["id"] > this.state.notificationId) 
                                this.setState({ notificationId: notification["id"] });

                            return notification;
                        })
                        .concat(this.state.notificationItems) // add notification to existing notificationItems
                        .sort((x,y) => x.triggered < y.triggered)
                        .slice(0, 100); // show max 100 notificationItems
                }

                this.debug && console.log("[Header] getNotifications", notificationItems)
                this.setState({ notificationItems })
            })
            .catch(error => {
                console.error("[Header] getNotifications", error);
                this.setState({ error });
            })
        }
    }

    onClickNotification = (item) => {
        this.props.select(item)
        this.props.currentTop !== "notifications" && this.props.history.push(ROUTES.NOTIFICATIONS) // redirect to notifications
    }

    onClearNotifications = () => {
        this.setState({ notificationItems: [] });
    }

    onClickArmed = () => {
        if (this.props.access) {
            const { isArmed } = this.state

            this.props.processMessage([{
                request: "armed",
                method: "set",
                params: [ isArmed ? 0 : 1 ]
            }])
            .then(res => {
                const armed = !!res["armed"].params[0]
                this.debug && console.log("[Header] onClickArmed", armed)
                if (armed !== isArmed) {
                    this.setState({ isArmed: armed })
                    message.info(armed ? "Armed" : "Disarmed")
                }
            })
            .catch(error => {
                console.error("[Header] onClickArmed", error);
                message.error("Device cannot be armed/disarmed: " + (error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again."));
            })
        }
    }

    onLogout = () => {
        const { processMessage, setAccess, history, setLoggedOut } = this.props

        this.setState({ openDrawer: false }, () => {
            processMessage([{
                request: "logout",
                method: "set",
                params: []
            }])
            .catch(error => {
                console.error("[Header] onLogout", error);
                this.setState({ error })
            })
            .finally(() => {
                removeCookie("__session"); // remove cookies
                setAccess(null); // remove accessrights
                history.push(ROUTES.LOGIN); // redirect to login
                setLoggedOut(true) // set manual logout
            })
        });
    }

    onClickAudioIconSmallLayout = () => {
        this.setState(prevState => ({
            isRecordingSmallLayout: !prevState.isRecordingSmallLayout
        }))
    }

    onClickAudioIconMediumLayout = () => {
        this.setState(prevState => ({
            isRecordingMediumLayout: !prevState.isRecordingMediumLayout
        }))
    }

    onClickAudioIconLargeLayout = () => {
        this.setState(prevState => ({
            isRecordingLargeLayout: !prevState.isRecordingLargeLayout
        }))
    }

    onOpenDrawer = (extendDrawer) => {
        this.setState({
            openDrawer: true,
            extendDrawer
        })
    }
    
    onCloseDrawer = () => {
        this.setState({
            openDrawer: false
        })
    }
    
    // show top navigation with left align logo, preselect item by currentTop props, disable items if socket fails or when not logged in
    render() {

        const { relayItems, isArmed, notificationItems, openDrawer, extendDrawer, isRecordingLargeLayout, isRecordingMediumLayout, isRecordingSmallLayout } = this.state
        const { currentTop, access } = this.props
        const disabled = !access || (currentTop && currentTop === "login")
        const isUser = access && access === "user" // setup disabled for user
        
        return (
            <div>

                <div className='largeTopMenu'>
                    <Menu 
                        mode="horizontal" 
                        selectedKeys={currentTop} 
                        style={{ height: "48px" }}
                        overflowedIndicator={<MenuOutlined style={{ marginRight: 0 }} />}
                    >

                        <Menu.Item key="logo" style={{ cursor: "unset" }} disabled>
                            <img src={Logo} alt="WCCTV" style={{ height: "36px", marginLeft: "24px" }} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, cursor: "default" }} disabled>
                            <ArmIcon isArmed={isArmed} disabled={disabled} onClick={this.onClickArmed} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "10px", cursor: "default" }} disabled>
                            <RelayIcon data={relayItems} setData={this.setRelayOutput} disabled={disabled} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "10px", cursor: "default" }} disabled>
                            <Button 
                                type={isRecordingLargeLayout ? "primary" : "default"}
                                shape="circle"
                                size="small"
                                disabled={disabled}
                                onClick={this.onClickAudioIconLargeLayout}
                            >                
                                {isRecordingLargeLayout
                                ? <><AudioFilled style={{ marginRight: 0 }} /><AudioIcon /></>
                                : <AudioMutedOutlined style={{ marginRight: 0 }} />}
                            </Button>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingRight: "10px", cursor: "default" }} disabled>
                            <PlayAudioComponent disabled={disabled} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingRight: 0, paddingLeft: 0, cursor: "unset" }} disabled>
                            <Divider style={{ height: "20px" }} type="vertical" />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right" }} key="logout" disabled={disabled} onClick={this.onLogout}>
                            <Icon type="logout" />Logout
                        </Menu.Item>

                        <Menu.Item style={{ float: "right" }} key="setup" disabled={disabled || isUser}>
                            <Link to={ROUTES.SETUP}>
                                <Icon type="setting" />Setup
                            </Link>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right" }} key="notifications" disabled={disabled}>
                            <Link to={ROUTES.NOTIFICATIONS}>
                                <Icon type="bell" />Notifications
                            </Link>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right" }} key="maintenance" disabled={disabled}>
                            <Link to={ROUTES.MAINTENANCE}>
                                <Icon type="dashboard" />Maintenance
                            </Link>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right" }} key="live" disabled={disabled}>
                            <Link to={ROUTES.LIVE}>
                                <Icon type="play-circle" />Live
                            </Link>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right" }} key="archive" disabled={disabled} >
                            <Link to={ROUTES.ARCHIVE}>
                                <Icon type="history" />Archive
                            </Link>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingRight: 0, paddingLeft: notificationItems.lengt ? "6px" : 0, cursor: "unset" }} disabled>
                            <Divider style={{ height: "20px" }} type="vertical" />
                        </Menu.Item>

                        <NotificationIcon 
                            data={notificationItems}
                            disabled={disabled}
                            onClick={this.onClickNotification}
                            onClear={this.onClearNotifications}
                        />
                        
                    </Menu>
                </div>

                <div className='mediumTopMenu'>
                    <Menu 
                        mode="horizontal" 
                        selectedKeys={currentTop} 
                        style={{ height: "48px" }}
                        overflowedIndicator={<MenuOutlined style={{ marginRight: 0 }} />}
                    >

                        <Menu.Item key="logo" style={{ cursor: "unset" }} disabled>
                            <img src={Logo} alt="WCCTV" style={{ height: "36px", marginLeft: "24px" }} />
                        </Menu.Item>      

                        <Menu.Item style={{ float: "right", paddingLeft: 0, cursor: "default" }} disabled>
                            <ArmIcon isArmed={isArmed} disabled={disabled} onClick={this.onClickArmed} />
                        </Menu.Item>      

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "10px", cursor: "default" }} disabled>
                            <RelayIcon data={relayItems} setData={this.setRelayOutput} disabled={disabled} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "10px", cursor: "default" }} disabled>
                            <Button 
                                type={isRecordingMediumLayout ? "primary" : null}
                                shape="circle"
                                size="small"
                                disabled={disabled}
                                onClick={this.onClickAudioIconMediumLayout}
                            >                
                                {isRecordingMediumLayout
                                    ? <><AudioFilled style={{ marginRight: 0 }} /><AudioIcon /></>
                                    : <AudioMutedOutlined style={{ marginRight: 0 }} /> 
                                }
                            </Button>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingRight: "10px", cursor: "default" }} disabled>
                            <PlayAudioComponent disabled={disabled} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingRight: 0, paddingLeft: 0, cursor: "unset" }} disabled>
                            <Divider style={{ height: "20px" }} type="vertical" />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right",  cursor: "default" }} disabled>
                            <Button onClick={() => this.onOpenDrawer(false)} size="small" type="link" disabled={disabled} style={{ padding: 0}}>
                                <MenuOutlined style={{ marginRight: 0 }} />
                            </Button>                        
                        </Menu.Item>
                        
                        <Menu.Item style={{ float: "right", paddingRight: 0, paddingLeft: notificationItems.length ? "6px" : 0, cursor: "unset" }} disabled>
                            <Divider style={{ height: "20px" }} type="vertical" />
                        </Menu.Item>

                        <NotificationIcon 
                            data={notificationItems}
                            disabled={disabled}
                            onClick={this.onClickNotification}
                            onClear={this.onClearNotifications}
                        />

                    </Menu>
                </div>

                <div className='smallTopMenu'>
                    <Menu 
                        mode="horizontal" 
                        selectedKeys={currentTop} 
                        style={{ height: "48px" }}
                        overflowedIndicator={<MenuOutlined style={{ marginRight: 0 }} />}
                    >

                        <Menu.Item key="logo" style={{ cursor: "unset", paddingRight: "12px", paddingLeft: "12px" }} disabled>
                            <img src={Logo} alt="WCCTV" style={{ height: "32px" }} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "12px", cursor: "default" }} disabled>
                            <ArmIcon isArmed={isArmed} disabled={disabled} onClick={this.onClickArmed} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "12px", cursor: "default" }} disabled>
                            <RelayIcon data={relayItems} setData={this.setRelayOutput} disabled={disabled} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: 0, paddingRight: "12px", cursor: "default" }} disabled>
                            <Button 
                                type={isRecordingSmallLayout ? "primary" : null}
                                shape="circle"
                                size="small"
                                disabled={disabled}
                                onClick={this.onClickAudioIconSmallLayout}
                            >                
                                {isRecordingSmallLayout
                                    ? <><AudioFilled style={{ marginRight: 0 }} /><AudioIcon /></>
                                    : <AudioMutedOutlined style={{ marginRight: 0 }} /> 
                                }
                            </Button>
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: "12px", paddingRight: "12px", cursor: "default" }} disabled>
                            <PlayAudioComponent disabled={disabled} />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingRight: 0, paddingLeft: 0, cursor: "unset" }} disabled>
                            <Divider style={{ height: "20px" }} type="vertical" />
                        </Menu.Item>

                        <Menu.Item style={{ float: "right", paddingLeft: "12px", paddingRight: "12px", cursor: "default" }} disabled>
                            <Button onClick={() => this.onOpenDrawer(true)} size="small" type="link" disabled={disabled} style={{ padding: 0}}>
                                <MenuOutlined style={{ marginRight: 0 }} />
                            </Button>                        
                        </Menu.Item>
                        
                        <Menu.Item style={{ float: "right", paddingRight: 0, paddingLeft: notificationItems.length ? "6px" : 0, cursor: "unset" }} disabled>
                            <Divider style={{ height: "20px" }} type="vertical" />
                        </Menu.Item>

                        <NotificationIcon 
                            data={notificationItems}
                            disabled={disabled}
                            onClick={this.onClickNotification}
                            onClear={this.onClearNotifications}
                        />

                    </Menu>

                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer}
                        visible={openDrawer}
                        style={{ padding: 0 }}
                    >
                        <Menu 
                            mode="inline" 
                            selectedKeys={currentTop} 
                            style={{ height: "48px" }}
                            overflowedIndicator={<MenuOutlined style={{ marginRight: 0 }} />}
                            onSelect={this.onCloseDrawer}
                        >
                            
                            <Menu.Item key="archive" disabled={disabled} >
                                <Link to={ROUTES.ARCHIVE}>
                                    <Icon type="history" />Archive
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="live" disabled={disabled}>
                                <Link to={ROUTES.LIVE}>
                                    <Icon type="play-circle" />Live
                                </Link>
                            </Menu.Item>
                            
                            <Menu.Item key="maintenance" disabled={disabled}>
                                <Link to={ROUTES.MAINTENANCE}>
                                    <Icon type="dashboard" />Maintenance
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="notifications" disabled={disabled}>
                                <Link to={ROUTES.NOTIFICATIONS}>
                                    <Icon type="bell" />Notifications
                                </Link>
                            </Menu.Item>
                            
                            {extendDrawer ? (
                                <Menu.SubMenu
                                    key="setup"
                                    disabled={disabled || isUser}
                                    title={<><Icon type="setting" />Setup</>}
                                >
                                    <Sider onSelect={this.onCloseDrawer} />
                                </Menu.SubMenu>
                            ) : (
                                <Menu.Item 
                                    key="setup" 
                                    disabled={disabled || isUser}
                                >
                                    <Link to={ROUTES.SETUP}>
                                        <Icon type="setting" />Setup
                                    </Link>
                                </Menu.Item>
                            )}

                            <Menu.Item key="logout" disabled={disabled} onClick={this.onLogout}>
                                <Icon type="logout" />Logout
                            </Menu.Item>

                        </Menu>
                    </Drawer>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Header));