import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';

const { DEBUG } = window.config

const cookieAttributes = {
    sameSite: "None",
    secure: true,
    path: "/",
    domain: `.${window.location.hostname}`
}

export function getCookie(name) {
    return Cookies.get(name)
}

export function setCookie(name, token) {
    try {
        let expiresIn = jwt.decode(token)?.exp
        Cookies.set(name, token, {
            expires: expiresIn ? dayjs.unix(expiresIn).toDate() : 365,
            ...cookieAttributes
        })
    } catch (error) {
        console.error(error)
    }
}

export function removeCookie(name) {
    Cookies.remove(name, cookieAttributes)
}

const isBlob = input => 'Blob' in window && input instanceof Blob;

export const saveFile = async(file, name) => {
    const a = document.createElement('a')
    a.download = name
    if (isBlob(file)) {
        a.href = URL.createObjectURL(file)
        a.addEventListener('click', () => setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000))
    } else {
        a.href = file
    }
    a.click()
    a.remove()
}

export function checkIOS() {

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // detect iPad, iPod or iPhone
    if (/iP(ad|od|hone)/i.test(userAgent))
        return true;

    // try to detect lying Safari Mobile in desktop mode
    if (userAgent.match(/Macintosh/i)) {
        var canvas = document.createElement("canvas");
        if (canvas) {
            var context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
            if (context) {
                var info = context.getExtension("WEBGL_debug_renderer_info");
                if (info) {
                    var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
                    if (renderer.indexOf("Apple") !== -1) {
                        var touchscreen = checkTouchscreen()
                        if (touchscreen)
                            return true;
                    }
                }
            }
        }
    }

    // return !!/iP(ad|od|hone)/i.test(userAgent);
    return false;
}

function checkTouchscreen() {
    if (window.PointerEvent && ("maxTouchPoints" in navigator)) { // if Pointer Events are supported, check maxTouchPoints
        if (navigator.maxTouchPoints > 0)
            return true;
    } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) { // any-pointer:coarse mostly means touchscreen
            return true;
        } else if (window.TouchEvent || ("ontouchstart" in window)) { // exposed touch events API/event handler
            return true;
        }
    }
    
    return false;
}

export function loadScript(name) {
    var script = document.createElement("script")

    script.src = `${window.PUBLIC_URL}/${name}.js`
    script.id = name
    script.async = true

    document.head.appendChild(script)
}

export function unloadScript(name) {
    document.head.removeChild(document.getElementById(name))
}

export const checkTURNServer = (iceServers, timeout = 5000) => {

    return new Promise(async(resolve, reject) => {

        var promiseResolved = false
        var connection = new RTCPeerConnection({ iceServers })
        var noop = () => {}

        setTimeout(() => {
            if (promiseResolved) {
                return
            }
            resolve(false)
            promiseResolved = true
        }, timeout)
    
        // create a bogus data channel
        connection.createDataChannel("") 

        // create offer and set local description
        connection.createOffer(sdp => {
            if (sdp.sdp.indexOf('typ relay') > -1) { // sometimes sdp contains the ice candidates
                promiseResolved = true
                resolve(true)
            }
            connection.setLocalDescription(sdp, noop, noop)
        }, noop)

        // listen for candidate events
        connection.onicecandidate = ice => {  
            if (promiseResolved || !(ice?.candidate?.candidate?.indexOf('typ relay') > -1)) {
                return
            }
            promiseResolved = true
            resolve(true)
        }
    })
}

export function debounce(func, delay) {

    let timer;
    
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(this, arguments);
        }, delay);
    };
}

export function stringToHex(str) {

    let hexString = ''

    for (let i = 0; i < str.length; i++) {
        let hex = str.charCodeAt(i).toString(16)
        hexString += hex
    }

    return hexString
}

export const isMobileApp = !!window.ReactNativeWebView // static check if frontend is accessed from mobile app

export function sendDataToMobileApp(data) {
    DEBUG && console.log('[Utilities] Sending data to mobile app.', data)
    window.ReactNativeWebView.postMessage(data)
}

export function roundToTwoDecimals(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100
}

export function unixToFormatted(unix) {
    return dayjs.unix(unix).format('YYYY-MM-DD HH:mm:ss')
}

export function isSmallBreakpoint() {
    return window.matchMedia('(max-width: 576px)').matches
}

export function getTableHeights(isSm) {

    const tolerance = isSm ? 8 : 1

    return (
        isSm ? {
            rowHeight: 93 + tolerance,
            headersHeight: 48 + 114 + 32 + tolerance // greater table header than in desktop
        } : {
            rowHeight: 27 + tolerance,
            headersHeight: 48 + 41 + 32 + tolerance // header, table header, controls
        }
    )
}