const initialState = {
    access: null,
    device: null,
    record: null,
    currentTop: null,
    currentLeft: null,
    processMessage: () => {},
    sortMessage: () => {},
    unit: {},
    loggedOut: false,
    selectedDevice: {},
    isMobileAppIOS: false,
    orientation: "portrait"
};

function rootReducer(state = initialState, action) {

    switch (action.type) {
        case "SELECT":
            return Object.assign({}, state, { record: action.payload });
        case "ONVIF": 
            return Object.assign({}, state, { device: action.payload });
        case "TOP": 
            return Object.assign({}, state, { currentTop: action.payload });
        case "LEFT": 
            return Object.assign({}, state, { currentLeft: action.payload });
        case "SETPROCESS": 
            return Object.assign({}, state, { processMessage: action.payload });
        case "SETSORT": 
            return Object.assign({}, state, { sortMessage: action.payload });
        case "SET": 
            return Object.assign({}, state, { unit: action.payload });
        case "SETACCESS": 
            return Object.assign({}, state, { access: action.payload }); 
        case "SETLOGGEDOUT": 
            return Object.assign({}, state, { loggedOut: action.payload }); 
        case "SETSELECTEDDEVICE": 
            return Object.assign({}, state, { selectedDevice: action.payload }); 
        case "SETISMOBILEAPPIOS": 
            return Object.assign({}, state, { isMobileAppIOS: action.payload }); 
        case "SETORIENTATION": 
            return Object.assign({}, state, { orientation: action.payload }); 
        default:
            return state;
    }
};

export default rootReducer;