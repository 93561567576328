import React from 'react';
import * as ROUTES from '../../constants/routes';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function mapStateToProps(state) {
    return { 
        currentLeft: state.currentLeft,
        currentTop: state.currentTop,
        access: state.access
    };
}

class Sider extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            openKeys: []
        }
    }

    componentDidMount() {

        if (!this.props.currentLeft
        || this.props.currentLeft === "analytics"
        || this.props.currentLeft === "connectivity"
        || this.props.currentLeft === "users") {
            this.setState({ openKeys: ["system"] });
        } else {
            this.setState({ openKeys: [] });
        }
    }

    clickSubmenu = key => {
        if (this.state.openKeys.length) {
            this.setState({ openKeys: [] });
        } else {
            this.setState({ openKeys: [key.key] });
        }
    }

    clickItem = key => {
        if (key.key === "analytics"
        || key.key === "connectivity"
        || key.key === "maintenance"
        || key.key === "users") {
            this.setState({ openKeys: ["system"] });
        } else {
            this.setState({ openKeys: [] });
        }
    }

    render() {

        const disabled = this.props.currentTop ? (this.props.currentTop === "login" ? true : false) : true
        const access = this.props.access
        const isUser = access && access === "user"
        const isMaintainer = access && access === "maintainer"

        return (
            <Menu 
                mode="inline" 
                selectedKeys={this.props.currentLeft}
                openKeys={this.state.openKeys}
                onSelect={this.clickItem && this.props.onSelect} // close Drawer of Header (responsive top menu)
            >

                <Menu.Item key="devices" disabled={disabled || !access}>
                    <Link to={ROUTES.DEVICES}>
                        Devices
                    </Link>
                </Menu.Item>

                <Menu.Item key="conditions" disabled={disabled || !access}>
                    <Link to={ROUTES.CONDITIONS}>
                        Conditions
                    </Link>
                </Menu.Item>

                <Menu.Item key="actions" disabled={disabled || !access}>
                    <Link to={ROUTES.ACTIONS}>
                        Actions
                    </Link>
                </Menu.Item>

                <Menu.Item key="events" disabled={disabled || !access}>
                    <Link to={ROUTES.EVENTS}>
                        Events
                    </Link>
                </Menu.Item>

                <Menu.Item key="alarms" disabled={disabled || !access}>
                    <Link to={ROUTES.ALARMS}>
                        Alarms
                    </Link>
                </Menu.Item>

                <Menu.SubMenu key="system" title="System" onTitleClick={this.clickSubmenu} disabled={disabled || !access}>

                    <Menu.Item key="analytics" disabled={disabled || !access}>
                        <Link to={ROUTES.ANALYTICS}>
                            Analytics
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="connectivity" disabled={disabled || !access || isUser || isMaintainer}>
                        <Link to={ROUTES.CONNECTIVITY}>
                            Configuration
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="users" disabled={disabled || !access}>
                        <Link to={ROUTES.USERS}>
                            Users
                        </Link>
                    </Menu.Item>
                    
                </Menu.SubMenu>
            </Menu>
        )
    }
}

export default connect(mapStateToProps,null)(Sider);