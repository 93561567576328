import React, { PureComponent } from 'react'

class PictureInPicture extends PureComponent {
    
    constructor(props) {
        super(props)

        this.state = {
            pos: "bottom-right",
            isFullscreen: false,
            imgSrc: this.props.imgSrc
        }

        this.refreshInterval = null
        this.refreshCount = 0
        this.refreshRepeat = 1000
        this.debug = window.config.DEBUG
    }

    componentDidMount() {
        this.refreshInterval = setInterval(() => {
            this.refreshCount = this.refreshCount + 1
            const imgSrc = `${this.props.imgSrc}?interval=${this.refreshCount}`
            this.debug && console.log("[PictureInPicture] Refresh", imgSrc)
            this.setState({ imgSrc })
        }, this.refreshRepeat)
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval)
    }

    onTopRight = () => {
        this.setState({ pos: "top-right" })
    }

    onTopLeft = () => {
        this.setState({ pos: "top-left" })
    }

    onBottomRight = () => {
        this.setState({ pos: "bottom-right" })
    }

    onBottomLeft = () => {
        this.setState({ pos: "bottom-left" })
    }

    onToggleFullscreen = () => {
        this.setState({ isFullscreen: !this.state.isFullscreen })
    }

    render() {
        const { pos, isFullscreen, imgSrc } = this.state
        var left, right, top, bottom, width

        switch (pos) {
            case "bottom-right":
                right = 24
                bottom = 32
                break;
            case "bottom-left":
                left = 24
                bottom = 32
                break;
            case "top-left":
                left = 24
                top = 32
                break;
            case "top-right":
                right = 24
                top = 32
                break;
            default:
                break;
        }

        if (isFullscreen) {
            width = "unset"
        } else {
            width = "240px"
        }

        const containerStyle = {
            zIndex: 1,
            position: 'absolute',
            cursor: 'pointer'
        }

        const rowStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }

        const iconStyle = {
            color: 'white',
            fontSize: "16px"
        }

        return (
            !!imgSrc && 
            <div
                style={{
                    ...containerStyle,
                    right,
                    bottom,
                    left,
                    top,
                    width
                }}
            >
                <div style={{ ...rowStyle }}>

                    <div className='mediumPlayer'>
                    
                        {!isFullscreen &&
                        pos === "bottom-right" &&
                        <div
                            onClick={this.onTopRight}
                            className='icon-up-pip' 
                            style={{
                                ...iconStyle,
                                paddingLeft: "24px"
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "bottom-left" &&
                        <div
                            onClick={this.onTopLeft}
                            className='icon-up-pip' 
                            style={{
                                ...iconStyle,
                                paddingRight: "24px"
                            }} 
                        />}

                    </div>

                    <div className='smallPlayer'>

                        {!isFullscreen &&
                        pos === "bottom-right" &&
                        <div
                            onClick={this.onTopRight}
                            className='icon-up' 
                            style={{
                                ...iconStyle,
                                paddingLeft: "24px"
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "bottom-left" &&
                        <div
                            onClick={this.onTopLeft}
                            className='icon-up' 
                            style={{
                                ...iconStyle,
                                paddingRight: "24px"
                            }} 
                        />}

                    </div>

                </div>

                <div style={{ ...rowStyle }}>

                    <div className='mediumPlayer'>

                        {!isFullscreen &&
                        pos === "bottom-right" &&
                        <div
                            onClick={this.onBottomLeft}
                            className='icon-left-pip' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "top-right" &&
                        <div
                            onClick={this.onTopLeft}
                            className='icon-left-pip' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                    </div>

                    <div className='smallPlayer'>
                            
                        {!isFullscreen &&
                        pos === "bottom-right" &&
                        <div
                            onClick={this.onBottomLeft}
                            className='icon-left' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "top-right" &&
                        <div
                            onClick={this.onTopLeft}
                            className='icon-left' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                    </div>

                    <img
                        alt="PiP"
                        onClick={this.onToggleFullscreen}
                        src={imgSrc}
                        style={{ width: isFullscreen ? '100%' : '90%' }}
                    />

                    <div className='mediumPlayer'>

                        {!isFullscreen &&
                        pos === "bottom-left" &&
                        <div
                            onClick={this.onBottomRight}
                            className='icon-right-pip' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "top-left" &&
                        <div
                            onClick={this.onTopRight}
                            className='icon-right-pip' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                    </div>

                    <div className='smallPlayer'>
                            
                        {!isFullscreen &&
                        pos === "bottom-left" &&
                        <div
                            onClick={this.onBottomRight}
                            className='icon-right' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "top-left" &&
                        <div
                            onClick={this.onTopRight}
                            className='icon-right' 
                            style={{
                                ...iconStyle,
                                width: '10%'
                            }} 
                        />}

                    </div>

                </div>

                <div style={{ ...rowStyle }}>
                    
                    <div className='mediumPlayer'>

                        {!isFullscreen &&
                        pos === "top-right" &&
                        <div
                            onClick={this.onBottomRight}
                            className='icon-down-pip' 
                            style={{
                                ...iconStyle,
                                paddingLeft: "24px"
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "top-left" &&
                        <div
                            onClick={this.onBottomLeft}
                            className='icon-down-pip' 
                            style={{
                                ...iconStyle,
                                paddingRight: "24px"
                            }} 
                        />}

                    </div>

                    <div className='smallPlayer'>
                        
                        {!isFullscreen &&
                        pos === "top-right" &&
                        <div
                            onClick={this.onBottomRight}
                            className='icon-down' 
                            style={{
                                ...iconStyle,
                                paddingLeft: "24px"
                            }} 
                        />}

                        {!isFullscreen &&
                        pos === "top-left" &&
                        <div
                            onClick={this.onBottomLeft}
                            className='icon-down' 
                            style={{
                                ...iconStyle,
                                paddingRight: "24px"
                            }} 
                        />}

                    </div>

                </div>

            </div>
        )
    }
}

export default PictureInPicture