import React, { Fragment } from 'react';
import Status from './Status';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import { NOTIFICATIONS } from '../constants/routes';
import { connect } from "react-redux";
import { Table, Icon, Button, Affix, Input, Layout, List } from 'antd';
import { left, select, top } from "../api/actions";
import { debounce, getTableHeights, isSmallBreakpoint, unixToFormatted } from './Utilities';

function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        record: state.record,
        sortMessage: state.sortMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        left: currentLeft => dispatch(left(currentLeft)),
        select: record => dispatch(select(record)),
        top: currentTop => dispatch(top(currentTop))
    };
}

class Notifications extends React.PureComponent {

    constructor(props) {
        super(props);

        const { rowHeight, headersHeight } = getTableHeights(isSmallBreakpoint());

        this.state = {
            data: [],
            isLoaded: false,
            error: null,
            filtered: false,
            searchText: "",
            searchedColumn: "",
            id: 0,
            pagination: {
                current: 1,
                defaultCurrent: 1,
                defaultPageSize: Math.round((window.innerHeight - headersHeight) / rowHeight),
                total: 10000
            },
            filters: {}
        };

        this.showDelayed = debounce(() => this.show.bind(this, this.state.pagination, this.state.filters)(), 1000); // calls debounce() which calls show() with 1s delay
        this.debug = window.config.DEBUG
        this.nvrLabel = "NVR-Device"
    }

    componentDidMount() {
        this.props.top("notifications")
        this.props.left(null)
        this.setState({ isLoaded: false }, () => this.show(this.state.pagination, this.state.filters)); // call show() initial
        this.timerID = setInterval(() => this.show(this.state.pagination, this.state.filters), 10000); // recall show() every 10s
        window.addEventListener('resize', this.showDelayed);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.showDelayed);
        clearInterval(this.timerID);
    }

    // only needed if Notification component is already opened but a user selects an item from NotificationIcon list
    // detecting the change of props paramterizes and calls the show function
    // if Notification component is not opened the show function is called in anyway
    componentDidUpdate(prevProps) {
        if (this.props.record && (prevProps.record !== this.props.record)) {

            const record = this.props.record;
            // var nextFilters = {...this.state.filters};
            var nextPagination = {...this.state.pagination};

            // nextFilters["event"] = [record.event];
            nextPagination["current"] = 1;

            this.setState({ id: record.id }, () => this.setState({
                // searchText: record.event,
                // searchedColumn: "event",
                // filters: nextFilters,
                // filtered: true,
                isLoaded: false
            }, () => this.show(nextPagination, this.state.filters)));
        }
    }

    getColumnSearchProps = dataIndex => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 188 }}
                >
                    Search
                </Button>

            </div>
        ),

        filterIcon: filtered => <Icon type="search" style={{ color: ((this.state.searchedColumn === dataIndex) && this.state.filtered) ? '#EE3350' : undefined }} />,

        filteredValue: [],

        render: text => {
            if (text) {
                if (this.state.searchedColumn === dataIndex) {
                    text = text.toString()
                    if (dataIndex === "triggered") {
                        text = unixToFormatted(text)
                    }
                    if (dataIndex === "message") {
                        text = text.replace(/utc:(\d+)/g, (match, unixTimestamp) => unixToFormatted(unixTimestamp))
                    }
                    return <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[this.state.searchText]}
                            autoEscape
                            textToHighlight={text}
                        />
                } else {
                    if (dataIndex === "message") {
                        return text.replace(/utc:(\d+)/g, (match, unixTimestamp) => unixToFormatted(unixTimestamp)) // search for UNIX timestamps in the string with regular expression and replace them
                    } else if (dataIndex === "triggered") {
                        return unixToFormatted(text);
                    } else { 
                        return text;
                    }
                }
            } else {
                return "-";
            }
        }
    })
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        
        confirm();

        this.setState({
            isLoaded: false,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        });
    }

    // in-/decrement current in state of pagination object and recall show
    move = direction => {

        const paginationState = {...this.state.pagination};
        
        if (direction === "next") {
            paginationState.current += 1;
        } else {
            paginationState.current -= 1;
        }
        
        this.setState({ isLoaded: false }, () => this.show(paginationState, this.state.filters));
    }

    // reset props, states and recall show()
    reset = () => {
        this.props.select(null);
        this.setState({ 
            id: 0,
            filtered: false,
            isLoaded: false,
            searchText: "",
            searchedColumn: "",
            filters: {}
        }, () => this.show(this.state.pagination, this.state.filters));
    }

    show = (pagination, filters) => {

        const { rowHeight, headersHeight } = getTableHeights(isSmallBreakpoint());
        const windowSize = Math.round((window.innerHeight - headersHeight) / rowHeight)
        const pageSize = windowSize > 3 ? windowSize : 3;

        var paramsObj = {};
        var optionsObj = {};
        var nextParams = [];
        var nextPagination = {...this.state.pagination};
        
        nextPagination.options = [];
        nextPagination.pageSize = pageSize;
        nextPagination.current = pagination ? pagination.current : 1;

        paramsObj["id"] = 0; // sort by id initially
        // paramsObj["triggered"] = 1; // sort by triggered initially
        nextParams.push(paramsObj);

        optionsObj["sort"] = "descend";
        nextPagination.options.push(optionsObj);

        // redirected from Archive or NotificationIcon
        if (this.props.record && typeof this.props.record.resetSelection === 'undefined') {

            var record = this.props.record
            // var isEventCollection = record.event == "EventCollection"
            // var nextFilters = {...this.state.filters};

            paramsObj = {};
            paramsObj["id"] = this.state.id ? this.state.id : record.id;
            nextParams.push(paramsObj);

            optionsObj = {};
            // optionsObj["filter"] = isEventCollection ? "le" : "eq"; // filter if id is lower (EventCollection) or equal (all other notifications) to database entry
            optionsObj["filter"] = "le";
            nextPagination.options.push(optionsObj);

            // nextFilters["event"] = [record.event];
            // this.setState({
            //     searchText: record.event,
            //     searchedColumn: "event",
            //     filters: nextFilters,
            //     filtered: true 
            // });

        // searching by filters in Notifications manually
        } else if (Object.keys(filters).length && this.state.searchedColumn && this.state.searchText) {

            paramsObj = {};
            optionsObj = {};

            // if searched column is not triggered, filter search text with "like"
            if (this.state.searchedColumn !== "triggered") {
                
                paramsObj[this.state.searchedColumn] = "%" + this.state.searchText + "%"; // % as placeholder for variable chars
                nextParams.push(paramsObj);

                optionsObj["filter"] = "like";
                nextPagination.options.push(optionsObj);

            // if searched column is triggered, convert to unix timestamp and filter with "greater then"
            } else { 

                var unixTimestamp = dayjs(this.state.searchText).unix()

                this.debug && console.log("[Notifications] searchText", this.state.searchText, "is a timestamp, lets convert it to unix", unixTimestamp)

                if (unixTimestamp) {

                    paramsObj[this.state.searchedColumn] = unixTimestamp
                    nextParams.push(paramsObj)
    
                    optionsObj["filter"] = "ge"
                    optionsObj["sort"] = "ascend"
                    nextPagination.options.push(optionsObj)
                }
            }

            this.setState({ filtered: true });
        }

        if (this.debug) {
            console.log(" ");
            console.log("nextParams", nextParams);
            console.log("nextPagination", nextPagination);
        }
        
        this.props.processMessage([{
            request: "notifications",
            method: "get",
            params: nextParams,
            pagination: nextPagination
        }])
        .then(res => 
            this.setState({ 
                pagination: nextPagination,
                filters: filters,
                data: res["notifications"].params
            }, () => {
                if (this.debug) {
                    console.log("filtersState", this.state.filters);
                    console.log("data", this.state.data);
                }
            }) 
        )
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }

    // color table row depending on record level
    getRowClassName = (record) => {
        if (record?.level === 3) { // alert
            return "table-row-red-alert";
        }

        if (record?.level === 2) { // error
            return "table-row-red";
        }

        if (record?.level === 1) { // warning
            return "table-row-orange";
        }   
    
        if (record?.level === 0) { // info
            return "table-row-green";
        }   

        return "table-row-white"
    };

    render() {
        
        const { error, isLoaded, data, pagination, filtered } = this.state;
        const isSm = isSmallBreakpoint();

        return (
            <Layout>
                <Layout.Content 
                    style={{
                        marginTop: "48px",
                        marginBottom: "32px",
                        height: 'calc(100% - 80px)'
                    }}
                >
                    {!error ?
                    <Fragment>
                        <Table
                            className={isSm ? "mobile-table" : undefined}
                            style={{ height: data.length ? "100%" : "unset" }}
                            key={pagination.pageSize}
                            pagination={pagination}
                            dataSource={data}
                            onChange={this.show}
                            size="small"
                            rowKey={record => record.id}
                            loading={!isLoaded}
                            scroll={isSm ? undefined : { x: 'max-content' }}
                            rowClassName={record => this.getRowClassName(record)}
                            columns={[
                                {
                                    dataIndex: "event",
                                    title: isSm ? "Search event" : "Event",
                                    ...this.getColumnSearchProps('event'),

                                },
                                {
                                    dataIndex: "triggered",
                                    title: isSm ? "Search triggered from" : "Triggered",
                                    ...this.getColumnSearchProps('triggered')
                                },
                                {
                                    dataIndex: "device",
                                    title: "Device",
                                    render: elem => elem ? elem : this.nvrLabel // TODO: only name available, need id to search
                                },
                                {
                                    dataIndex: "message",
                                    title: isSm ? "Search message" : "Message",
                                    ...this.getColumnSearchProps('message')
                                },
                                {
                                    width: 0,
                                    className: "ant-table-column-has-actions",
                                    title: () => <Button disabled={!filtered} type="primary" size="small" onClick={this.reset}>Reset filters</Button>
                                }
                            ]}
                            components={isSm ? {
                                body: {
                                    wrapper: () => data.length ? (
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <List
                                                        size='small'
                                                        dataSource={data}
                                                        renderItem={item => (
                                                            <List.Item className={this.getRowClassName(item)} style={{ padding: 4, display: "block", height: "unset" }} >
                                                                <div><strong>Event:</strong> {item.event}</div>
                                                                <div><strong>Triggered:</strong> {unixToFormatted(item.triggered)}</div>
                                                                <div><strong>Device:</strong> {item.device || this.nvrLabel}</div>
                                                                <div><strong>Message:</strong> {item.message.replace(/utc:(\d+)/g, (match, unixTimestamp) => unixToFormatted(unixTimestamp))}</div>
                                                            </List.Item>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : null
                                }
                            } : undefined}
                        />

                        <Affix offsetBottom="0" className="affix">

                            <Button.Group style={{ width: "100%" }}>

                                <Button 
                                    type="primary" 
                                    style={{ 
                                        width: "50%",
                                        borderBottomLeftRadius: 0,
                                        marginLeft: 0
                                    }} 
                                    disabled={(data.length < pagination.pageSize) || !isLoaded}
                                    onClick={() => this.move("next")}
                                >
                                    <Icon type="left" /> Older
                                </Button>

                                <Button 
                                    type="primary" 
                                    style={{ 
                                        width: "50%",
                                        borderBottomRightRadius: 0,
                                        marginLeft: 0
                                    }} 
                                    disabled={pagination.current <= 1 || !isLoaded} 
                                    onClick={() => this.move("previous")}
                                >
                                    Newer <Icon type="right" />
                                </Button>

                            </Button.Group>

                        </Affix>
                    </Fragment> :
                    <Status is={error} back={NOTIFICATIONS} />}
                </Layout.Content>
            </Layout>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Notifications);